import React, { FC } from "react";
import "./SocialMediaButtons.css";

export const SocialMediaButtons: FC<{ isFooter?: boolean }> = ({
  isFooter,
}) => {
  return (
    <div className="socials">
      <a className="button" href="//opensea.io/">
        Open Sea (coming soon)
      </a>
      <a className="button" href="https://twitter.com/MetaNinjaz">
        Twitter
      </a>
      <a className="button" href="https://discord.gg/5GcetZpSGb">
        Discord
      </a>
    </div>
  );
};
